#mm-toast {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50px;
  z-index: 111;
}

.mm-toast-anim {
  margin-top: 10px;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 1s;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0,100%,0);
            transform: translate3d(0,100%,0);
    visibility: visible
  }

  to {
    -webkit-transform: translateY(var(--height)) translateY(-10px);
            transform: translateY(var(--height)) translateY(-10px);
  }
}

@keyframes slideInUp {
  0% {
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      visibility: visible
  }

  to {
    -webkit-transform: translateY(var(--height)) translateY(-10px);
            transform: translateY(var(--height)) translateY(-10px);
  }
}