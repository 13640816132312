@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator, input[type="week"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    width: auto;
    @apply
    absolute
    top-0
    right-0
    bottom-0
    left-0
    cursor-pointer
  }
}
