#mm-floating {
  position: absolute;
  z-index: 2;
  right: 10px;
}

.mm-floating-notification-anim {
  margin-bottom: 10px;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 1s;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0,100%,0);
            transform: translate3d(0,100%,0);
    visibility: visible
  }

  to {
    -webkit-transform: translateY(var(--height)) translateY(-10px);
            transform: translateY(var(--height)) translateY(-10px);
  }
}

@keyframes slideInUp {
  0% {
      -webkit-transform: translate3d(0,0,0);
      transform: translate3d(0,0,0);
      visibility: visible
  }

  to {
    -webkit-transform: translateY(var(--height)) translateY(-10px);
            transform: translateY(var(--height)) translateY(-10px);
  }
}