@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.getmonad.com/assets/D1t2/latest.css");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');

body * {
  font-family: 'Rubik';
}

@layer base {
  :root {
    --color-primary: #0057FF;
    --color-primary-light: #6B7176;
    --color-primary-lighter: #E6EEFF;
    --color-primary-dark: #001BCB;

    --color-secondary: #2E384D;
    --color-secondary-light: #ABB5BE;
    --color-secondary-dark: #021747;

    --color-gray-100: #FAFAFD;
    --color-gray-200: #F5F5FB;
    --color-gray-300: #F4F4F5;
    --color-gray-400: #EFF0F7;
    --color-gray-500: #DEE2E6;
    --color-gray-600: #CBD4E6;
    --color-gray-700: #D5D7DB;
    --color-gray-800: #9FA3AD;
    --color-gray-900: #565E70;
  }
}

.rc-color-picker-wrap {
  height: 32px;
}
.rc-color-picker-panel, .rc-color-picker-panel-board-hsv {
  width: 100%;
}

/* Styles for media library start */
.calendly-overlay{
  z-index: 10000001;
}
.modal {
  max-width: 800px !important;
  padding: 0 !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}
 .close-modal { 
  background-image: url('https://icon2.cleanpng.com/20180610/llv/kisspng-computer-icons-oniru-close-5b1dc8c0212507.1966399215286785921358.jpg') !important;
  position: absolute !important;
  top: 15.5px !important;
  right: 13.5px !important;
  display: block !important;
  width: 12px !important;
  height: 12px !important;
  text-indent: -9999px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
 }
.btn-primary {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
.btn {
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  transition: color .15s !important;

}

.modal-header{
  display: flex !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
}
.nav-link {
  display: block !important;
  padding: 0.5rem 1rem !important;
}
.close {
  position: absolute !important;
  top: 0 !important;
  right: 5px !important;
  opacity: 0.5 !important;
  font-size: 25px !important;
}
.media-close {
  display: none !important;
}
.swal2-container{
  z-index: 10000002 !important;
}
.upload-btn {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  display: inline-block;
  font-weight: 400 !important;
  color: #fff !important;
  text-align: center !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  /* background-color: transparent !important; */
  border: 1px solid transparent !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
}
.upload-btn:hover {
  background-color: #0069d9 !important;
}

.form-control {
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Style for Phone Number Input */
.inp-cls{
  padding-left: 48px !important;
}


.filter-row {
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px 15px !important;
}

/* Styles for media library end */

.rc-color-picker-trigger {
  width: 32px !important;
  height: 32px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.CodeMirror {
  border: 1px solid #EFF0F7 !important;
  height: auto !important;
  border-radius: 4px 4px 4px 4px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.CodeMirror-sizer {
  margin-left: 33px !important;
  /* height: 32px !important; */
}

.CodeMirror .CodeMirror-placeholder {
  color: #999 !important;
}

.CodeMirror-line {
  padding: 0 8px !important;
  line-height: 16px !important;
}

.CodeMirror-line-like {
  padding: 0 8px !important;
}

.CodeMirror-lines {
  padding: 8px 0 !important;
}

.CodeMirror-linenumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CodeMirror-gutters {
  background: #EFF0F7 !important;
  width: 32px !important;
}

.CodeMirror-gutter {
  width: 32px !important;
}

.desktop-container {
  display: flex;
}

.blocker {
  z-index: 1000001 !important;
}
/* Journey Builder */
.mm-journey-step.selected .mm-journey-step-action {
  box-shadow: 0 0 10px var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.selectedCss {
  box-shadow: 0 0 10px var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.primary-bg{
  background-color: var(--color-primary) !important;
}
input[type="radio"]{
  accent-color: var(--color-primary) !important;
}

#scroller * {
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Mailmunch AI Chat */
.chat-bg {
  background: rgb(21,26,68);
  background: linear-gradient(90deg, rgba(21,26,68,1) 0%, rgba(35,22,94,1) 50%, rgba(21,26,68,1) 100%);
}
.bot-message-bg {
  background: linear-gradient(155.14deg, rgba(255, 255, 255, 0.1) -2.13%, rgba(255, 255, 255, 0.1) 136.58%);
  border: 1px solid #433E7A;
  box-shadow: 0px 4px 49px rgba(0, 7, 72, 0.12);
}
.chat-text-bg {
  background: linear-gradient(91.06deg, #FF1CF7 2.26%, #00F0FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.chat-icon-bg {
  background: linear-gradient(155.14deg, rgba(255, 255, 255, 0) -2.13%, rgba(255, 255, 255, 0.35) 136.58%);
  border: 1px solid #544B87;
  filter: drop-shadow(0px 4px 49px rgba(0, 7, 72, 0.12));
  backdrop-filter: blur(12.5px);
  border-radius: 20px;
}

/* Media Library */
.media-asset-background {
  border-radius: 0px 0px 8px 8px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #121212 100%);
}
.media-asset-background > span {
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.50);
}

@media (min-width: 768px) {
  .desktop-container {
      display: none;
  }
}

.blink-bg {
  animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
  0% { background-color: inherit; }
  20% { @apply bg-primary-lighter; }
  40% { background-color: inherit; }
  70% { @apply bg-primary-lighter; } 
  100% { background-color: inherit; }
}